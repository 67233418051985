import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { MatSelect } from '@angular/material';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Consumer } from '../../classes/consumer';
import { Provider } from '../../classes/provider';
import { Category } from '../../classes/category';

import { ConsumerService } from '../../services/consumer/consumer.service';
import { CategoryService } from '../../services/category/category.service';
import { ProviderService } from '../../services/provider/provider.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) stageSelection: MatSelect;

  @Input() public showStages = false;
  @Input() public showCategories = false;
  @Input() public showSorting = false;
  @Input() public showConsumer = false;
  @Input() public showStatus = false;
  @Input() public statusSimplified = false;
  @Input() public showProviders = false;
  @Input() public showMediaType = false;
  @Input() public showProductModel = false;
  @Input() public showCustomerType = false;
  @Input() public showProviderType = false;
  @Input() public showSelectionType = false;
  /**Wenn isolated=true werden Provider,Consumer und Category nicht mit den jeweiligen Services
   * synchronisiert. Das ist z.B. in Dialogen hilfreich, um nicht die Filterung der Hauptseite zu ändern.
   * Die Synchronisation sollte nur verwendet werden wenn man den Filter beim Seitenwechsel beibehalten will.
   */
  @Input() public isolated = false;

  @Input() public disabled = false;

  @Output() public stageChange$ = new EventEmitter();
  @Output() public sortingChange$ = new EventEmitter();
  @Output() public statusChange$ = new EventEmitter();
  @Output() public mediaTypeChange$ = new EventEmitter();
  @Output() public productModelChange$ = new EventEmitter();
  @Output() public providerChange$ = new EventEmitter();
  @Output() public consumerChange$ = new EventEmitter();
  @Output() public categoryChange$ = new EventEmitter();
  @Output() public customerTypeChange$ = new EventEmitter();
  @Output() public customerVerifyStatusChange$ = new EventEmitter();
  @Output() public providerTypeChange$ = new EventEmitter();
  @Output() public selectionTypeChange$ = new EventEmitter();
  @Output() public filterChange$ = new EventEmitter();

  public stages: Array<{group: string, name: string, shortname: string}>;

  public includedStages = [
    {name: 'Entwicklung', shortname: 'E', group: 'include'},
    {name: 'Test', shortname: 'T', group: 'include'},
    {name: 'Systemtest', shortname: 'ST', group: 'include'},
    {name: 'Vorproduktion', shortname: 'VP', group: 'include'},
    {name: 'Produktion', shortname: 'P', group: 'include'},
  ];
  public excludedStages = [
    {name: 'Entwicklung', shortname: 'E', group: 'exclude'},
    {name: 'Test', shortname: 'T', group: 'exclude'},
    {name: 'Systemtest', shortname: 'ST', group: 'exclude'},
    {name: 'Vorproduktion', shortname: 'VP', group: 'exclude'},
    {name: 'Produktion', shortname: 'P', group: 'exclude'}
  ];

  public sortings = [
    {id: 'alphabet', name: 'Alphabetisch', hint: 'Sortierung nach Alphabet, beginnend mit Zeichen und Nummern.'},
    {id: 'date', name: 'Neueste zuerst', hint: 'Sortierung nach Zeitpunkt der Anlage, beginnend mit dem Jüngsten.'}
  ];

  public statusList = [
    {id: 'laufend', name: 'Alle Status', hint: 'Nicht archivierte Produkte'},
    {id: 'live', name: 'Status "Live"', hint: 'Veröffentlichte Produkte'},
    {id: 'in_pruefung', name: 'Status "In Prüfung"', hint: 'Zur Veröffentlichung eingereichte Produkte' },
    {id: 'entwurf', name: 'Status "Entwurf"', hint: 'Gespeicherte, nicht veröffentlichte Produkte'},
    {id: 'aktuell', name: 'Status "Aktuell sichtbar"', hint: 'Aktuell sichtbare Produkte'},
    {id: 'zukuenftig', name: 'Status "In Zukunft sichtbar"', hint: 'Zukünftig sichtbare Produkte'},
    {id: 'abgelaufen', name: 'Status "Archiv"', hint: 'Archivierte, abgelaufene Produkte'}
  ];

  public mediaTypeList = [
    {id: null, name: 'Alle Typen', hint: 'Alle Medientypen anzeigen'},
    {id: 'PDF', name: 'Nur PDFs', hint: 'Es werden nur PDFs angezeigt'},
    {id: 'IMAGE', name: 'Nur Bilder', hint: 'Es werden nur Bilder angezeigt' },
    {id: 'VIDEO', name: 'Nur Videos', hint: 'Es werden nur Videos angezeigt'}
  ];

  public productModelList = [
    {id: null, name: 'Alle Modelle', hint: 'Alle Produktmodelle anzeigen'},
    {id: 'BUSINESS', name: 'Nur Business', hint: 'Es werden nur Business Produkte angezeigt'},
    {id: 'BUSINESS_PLUS', name: 'Nur Business Plus', hint: 'Es werden nur Business Plus Produkte angezeigt' },
    {id: 'BUSINESS_PRO', name: 'Nur Business Pro', hint: 'Es werden nur Business Pro Produkte angezeigt'},
    {id: 'FREE', name: 'Nur freie Produkte', hint: 'Es werden nur freie Produkte angezeigt'}
  ];

  public customerTypeList = [
    {id: null, name: 'Alle Kunden', hint: 'Alle Kunden anzeigen'},
    {id: 'BROKER', name: 'Nur Makler', hint: 'Es werden nur Makler angezeigt'},
    {id: 'INSURER', name: 'Nur Versicherer', hint: 'Es werden nur Versicherer angezeigt' },
    {id: 'EXTERN', name: 'Nur externe Systeme', hint: 'Es werden nur externe Systeme angezeigt' },
  ];

  public customerVerifyStatusList = [
    {id: null, name: 'Alle Status', hint: 'Alle Makler anzeigen'},
    {id: 'PARTNERPROGRAMM', name: 'Parterprogramm', hint: 'Es werden nur Makler aus dem Partnerprogramm angezeigt'},
    {id: 'ANBIETERPROGRAMM', name: 'Anbieterprogramm', hint: 'Es werden nur Makler aus dem Anbieterprogramm angezeigt'},
    {id: 'HOMEPAGEPROGRAMM', name: 'Homepageprogramm', hint: 'Es werden nur Makler aus dem Homepageprogramm angezeigt'},
    {id: 'VERIFIED', name: 'Verfizierte Anbieter', hint: 'Es werden nur verifizierte Makler aus dem Anbieterprogramm angezeigt'},
    // tslint:disable-next-line:max-line-length
    {id: 'NOT_VERIFIED', name: 'Anbieter in Prüfung', hint: 'Es werden nur Makler aus dem Anbieterprogramm angezeigt, die in Prüfung sind' },
  ];

  public providerTypeList = [
    {id: null, name: 'Alle Produktgeber', hint: 'Alle Produktgeber anzeigen'},
    {id: 'BROKER', name: 'Nur Makler', hint: 'Es werden nur Makler angezeigt'},
    {id: 'INSURER', name: 'Nur Versicherer', hint: 'Es werden nur Versicherer angezeigt' },
  ];


  public selectionTypeList = [
    {id: null, name: 'Alle', hint: 'Ausgewählte und nicht Ausgewählte'},
    {id: 'SELECTED', name: 'Nur Ausgewählte', hint: 'Nur Ausgewählte anzeigen'}
  ];

  private destructor: Subject<boolean> = new Subject();

  private isInitialized = false;
  private initialized: Subject<void> = new Subject();

  public selectedSorting: {id: string, name: string} = this.sortings[0];
  public selectedStatus: {id: string, name: string} = this.statusList[0];
  public selectedMediaType: {id: string, name: string} = this.mediaTypeList[0];
  public selectedProductModel: {id: string, name: string} = this.productModelList[0];
  public selectedCustomerType: {id: string, name: string} = this.customerTypeList[0];
  public selectedCustomerVerifyStatus: {id: string, name: string} = this.customerVerifyStatusList[0];
  public selectedProviderType: {id: string, name: string} = this.providerTypeList[0];
  public selectedSelectionType: {id: string, name: string} = this.selectionTypeList[0];
  public selectedConsumer: Consumer = null;
  public selectedProvider: Provider = null;
  public selectedCategory: Category = null;

  get selectedProviderId(): string {
      return (!this.selectedProvider || this.selectedProvider.ID === 'ALL') ? null : this.selectedProvider.ID;
  }
  get selectedCategoryId(): string {
      return (!this.selectedCategory) ? null : this.selectedCategory.ID;
  }
  get selectedConsumerId(): string {
      return (!this.selectedConsumer) ? null : this.selectedConsumer.ID;
  }
  get selectedStatusId(): string {
      return (!this.selectedStatus) ? null : this.selectedStatus.id;
  }
  get selectedMediaTypeId(): string {
      return (!this.selectedMediaType) ? null : this.selectedMediaType.id;
  }
  get selectedProductModelId(): string {
      return (!this.selectedProductModel) ? null : this.selectedProductModel.id;
  }
  get selectedSortingId(): string {
      return (!this.selectedSorting) ? null : this.selectedSorting.id;
  }
  get selectedCustomerTypeId(): string {
      return (!this.selectedCustomerType) ? null : this.selectedCustomerType.id;
  }
  get selectedCustomerVerifyStatusId(): string {
      return (!this.selectedCustomerVerifyStatus) ? null : this.selectedCustomerVerifyStatus.id;
  }
  get selectedProviderTypeId(): string {
      return (!this.selectedProviderType) ? null : this.selectedProviderType.id;
  }
  get selectedStageFilters(): {group: string, name: string}[] {
      return this.stages;
  }
  get selectedSelectionTypeId(): string {
      return (!this.selectedSelectionType) ? null : this.selectedSelectionType.id;
  }

  public onInit(cb: () => void) {
    if (this.isInitialized) {
        cb();
    } else {
        this.initialized.subscribe({
            complete: cb
        });
    }
  }

  public get consumers() {
      return this.consumerService.consumerList;
  }

  public get providers() {
      return this.providerService.providerFilterList;
  }

  public get categories() {
      return this.categoryService.categoryList;
  }

  constructor(
    private consumerService: ConsumerService,
    private categoryService: CategoryService,
    private providerService: ProviderService,
    public authService: AuthService
  ) {
    this.stages = [];
    if (this.isolated) {
        // In einem isolierten Filter macht es keinen Sinn einen Consumer-Filter anzubieten,
        // weil beim Wechsel des Consumers Kategorien und Anbieter neu geladen werden müssen
        this.showConsumer = false;
    }
  }

  ngOnInit() {
    if (this.showStages) {
      this.includedStages.forEach(stage => {
        this.stages.push(stage);
      });
    }
    if (this.statusSimplified) {
      // tslint:disable-next-line:max-line-length
      this.statusList = this.statusList.filter(entry => entry.id === 'laufend' || entry.id === 'abgelaufen' || entry.id === 'zukuenftig' || entry.id === 'aktuell');
    }

    this.selectedConsumer = this.consumerService.selectedConsumer;
    if (this.showConsumer) {
        this.consumerService.selectedConsumerChange$.
        pipe(takeUntil(this.destructor)).
        subscribe(consumer => {
            if (!this.isolated && consumer.ID !== this.selectedConsumerId) {
                this.selectedConsumer = consumer;
                this.consumerChange$.emit(consumer);
            }
        });
    }

    if (!this.selectedProvider) {
      this.selectedProvider = this.providerService.selectedProvider;
    }
    if (this.showProviders) {
        this.providerService.selectedProviderChange$.
        pipe(takeUntil(this.destructor)).
        subscribe(provider => {
            if (!this.isolated && provider.ID !== this.selectedProviderId) {
                this.selectedProvider = provider;
                this.providerChange$.emit(provider);
            }
        });
    }

    this.selectedCategory = this.categoryService.selectedCategory;
    if (this.showCategories) {
        this.categoryService.selectedCategoryChange$.
        pipe(takeUntil(this.destructor)).
        subscribe(category => {
            if (!this.isolated && category.ID !== this.selectedCategoryId) {
                this.selectedCategory = category;
                this.categoryChange$.emit(category);
            }
        });
    }

    if(!this.authService.isSystem()) {
      this.productModelList = this.productModelList.filter(e => e.id !== 'FREE');
    }

    merge(
        this.stageChange$,
        this.sortingChange$,
        this.statusChange$,
        this.mediaTypeChange$,
        this.productModelChange$,
        this.providerChange$,
        this.consumerChange$,
        this.categoryChange$,
        this.customerTypeChange$,
        this.customerVerifyStatusChange$,
        this.providerTypeChange$,
        this.selectionTypeChange$
    ).
    pipe(takeUntil(this.destructor)).
    subscribe(_ => {
        this.filterChange$.emit();
    });

    this.isInitialized = true;
    this.initialized.complete();
  }

  ngOnDestroy() {
      this.destructor.next(true);
      this.destructor.complete();
  }

  stageSelectionChange(event: any) {
    if (!event.isUserInput) {
      return;
    }

    const stageChanged = event.source.value;
    const newSelection = event.source.selected;
    for (let i = 0; i < this.stages.length; i++) {
      if (stageChanged.name === this.stages[i].name && (
        (stageChanged.group !== this.stages[i].group && newSelection) ||
        (stageChanged.group === this.stages[i].group && !newSelection)
      )) {
        this.stages.splice(i, 1);
        break;
      }
    }

    this.stageSelection.writeValue(this.stages);
    setTimeout(() => {
      this.stageChange$.emit(this.stages);
    }, 10);
  }

  countIncludedStages(): number {
    return this.stages.filter(stage => stage.group === 'include').length;
  }

  countExcludedStages(): number {
    return this.stages.filter(stage => stage.group === 'exclude').length;
  }

  compareFn(a: {group: string, name: string}, b: {group: string, name: string}) {
    return a.name === b.name && a.group === b.group;
  }

  changeConsumer(consumer: Consumer) {
    this.selectedConsumer = consumer;
    if (!this.isolated) {
        this.consumerService.selectedConsumer = consumer;
    }
    this.consumerChange$.emit(consumer);
  }

  changeCategory(category: Category) {
    this.selectedCategory = category;
    if (!this.isolated) {
        this.categoryService.selectedCategory = category;
    }
    this.categoryChange$.emit(category);
  }

  changeProvider(provider: Provider) {
    this.selectedProvider = provider;
    if (!this.isolated) {
        this.providerService.selectedProvider = provider;
    }
    this.providerChange$.emit(provider);
  }

  changeSorting(sorting: {id: string, name: string}) {
    this.selectedSorting = sorting;
    this.sortingChange$.emit(sorting);
  }

  changeStatus(status: {id: string, name: string}) {
    this.selectedStatus = status;
    this.statusChange$.emit(status);
  }

  changeMediaType(mediaType: {id: string, name: string}) {
    this.selectedMediaType = mediaType;
    this.mediaTypeChange$.emit(mediaType);
  }

  changeProductModel(productModel: {id: string, name: string}) {
    this.selectedProductModel = productModel;
    this.productModelChange$.emit(productModel);
  }

  changeCustomerType(customerType: {id: string, name: string}) {
    this.selectedCustomerType = customerType;
    if (customerType.id !== 'BROKER') {
      this.selectedCustomerVerifyStatus = this.customerVerifyStatusList[0];
    }

    this.customerTypeChange$.emit(customerType);
  }

  changeCustomerVerifyStatus(customerVerifyStatus: {id: string, name: string}) {
    this.selectedCustomerVerifyStatus = customerVerifyStatus;
    this.customerVerifyStatusChange$.emit(customerVerifyStatus);
  }

  changeProviderType(providerType: {id: string, name: string}) {
    this.selectedProviderType = providerType;
    this.providerTypeChange$.emit(providerType);
  }

  changeSelectionType(selectionType: {id: string, name: string}) {
    this.selectedSelectionType = selectionType;
    this.selectionTypeChange$.emit(selectionType);
  }

  refreshFilter() {
    this.filterChange$.emit();
  }
}
