import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import * as _moment from 'moment';

import { ItemComponent } from '../../classes/item-component';
import { Editor } from '../../classes/editor';
import { Product } from '../../classes/product';
import { Category } from '../../classes/category';
import { GenericDialog } from '../../classes/generic-dialog';

import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { LeistungsEditorDialogComponent } from '../dialog/leistungseditor-dialog/leistungseditor-dialog.component';
import { FormEditorDialogComponent } from '../dialog/form-editor-dialog/form-editor-dialog.component';
import { EditorService } from '../../services/editor/editor.service';
import { AuthService } from '../../services/auth/auth.service';
import { ProductService } from '../../services/product/product.service';
import { ProviderService } from '../../services/provider/provider.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { CategoryService } from '../../services/category/category.service';
import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MediaSetService } from '../../services/media-set/media-set.service';
import { MediaSet } from 'src/app/classes/media-set';
import { ImagePreviewDialogComponent } from '../dialog/image-preview-dialog/image-preview-dialog.component';
import { ImagePreviewDialog } from 'src/app/classes/image-preview-dialog';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { map } from 'rxjs/operators';
import { ProductFreeItemComponent } from '../product-free-item/product-free-item.component';
import { Provider } from 'src/app/classes/provider';

const moment = _moment;

export class ProductEditor extends Editor<ItemComponent, any> {

  public title = '...';

  constructor(
    public component: ProductItemComponent | ProductFreeItemComponent,
    private router: Router,
    public dialog: MatDialog,
    private productService: ProductService,
    private messageService: MessageService,
    private editorService: EditorService
  ) {
    super(component);
  }

  save(model: any, subscriber: any) {
    this.submitModel(model).subscribe((product: Product) => {
      console.log("save ", model);
      
      model.Version = product.Version;
      model.Aenderungsdatum = product.Aenderungsdatum;
      this.editorService.setModel(null);
      subscriber.next();
      subscriber.complete();
      
      if (!model.ID) {
        this.router.navigate(['/product-edit', product.ID]);
      } else if (this.router.url !== '/product-list') {
        this.router.navigate(['/product-list']);
      } else {
        this.component.editProduct(false);
      }
    }, error => {
      this.messageService.showError('Beim Aktualisieren des Produktes ist es zu einem Fehler gekommen: '
        + error.error.message, error);
      subscriber.error(error);
    });
  }

  submit(model: any, type: string): Observable<void> {


    return new Observable(subscriber => {
      if (type === 'publish') {
        const genericDialog = new GenericDialog();
        genericDialog.titel = 'Veröffentlichung bestätigen';
        genericDialog.text = '<p>Sie sind im Begriff, das Produkt zur Publikation einzureichen. \
          Nach einer Überprüfung und Freigabe durch einen \
		  unserer Mitarbeiter wird Ihr Produkt über Nacht in unseren \
		  Index aufgenommen und kann anschließend gefunden werden. </p><p>Wollen Sie den Vorgang fortsetzen?</p>';
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          maxWidth: '500px',
          maxHeight: '100vh',
          data: genericDialog
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            model.Status = 'IN_PRUEFUNG';
            this.save(model, subscriber);
          } else {
            subscriber.complete();
          }
        });
      } else if (type === 'confirm') {
        const genericDialog = new GenericDialog();
        genericDialog.titel = 'Freigabe bestätigen';
        genericDialog.text = 'Sie sind im Begriff, das Produkt freizugeben. \
          Dieser Schritt ist unwiderruflich. Möchten Sie den \
          Vorgang fortsetzen?';
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          maxWidth: '500px',
          maxHeight: '100vh',
          data: genericDialog
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            model.Status = 'FREIGEGEBEN';
            this.save(model, subscriber);
          } else {
            subscriber.complete();
          }
        });
      } else {
        this.save(model, subscriber);
      }
    });
  }
  submitModel(model): Observable<Product> {
    return new Observable(subscriber => {
      this.productService.update(model).subscribe((product: Product) => {
        model.MediaSet = product.MediaSet;
        subscriber.next(product);
        subscriber.complete();
      }, (error) => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  delete(model): Observable<void> {
    console.log('delete -> ' + model.ID);
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Löschvorgang bestätigen';
    genericDialog.text = 'Sie sind im Begriff, das Produkt zu löschen. \
      Dieser Schritt ist unwiderruflich. Möchten Sie den \
      Löschvorgang fortsetzen?';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
    return new Observable(subscriber => {
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.productService.delete(model.ID).subscribe(() => {
            subscriber.next();
            this.router.navigate(['/product-list']);
          }, error => {
            subscriber.error(error);
            this.messageService.showError('Beim Löschen des Produktes ist es zu einem Fehler gekommen: '
              + error.error.message, error);
          }); // OK -> route observable from productService
        } else {
          // CANCEL -> not error, not next, instead call complete
          subscriber.complete();
        }
      });
    });
  }

  preModelModifier(model: any): void {

    if (model.Suchbegriffe instanceof Array) {
      model.Suchbegriffe = model.Suchbegriffe.join(' ');
    }
    if (model.Risikotraeger instanceof Array) {
      model.Risikotraeger = model.Risikotraeger.join(', ');
    }
    if (model.Filter && typeof model.Filter !== 'string') {
      model.Filter = JSON.stringify(model.Filter);
    }

    if (model.Defaults && typeof model.Defaults !== 'string') {
      model.Defaults = JSON.stringify(model.Defaults);
    }

    if (model.Preis && typeof model.Preis !== 'string') {
      model.Preis = model.Preis.toFixed(2);
    }

  }


  postModelModifier(model: any, type: string): void {
    model.Suchbegriffe = model.Suchbegriffe.toString().trim().split(' ');
    for (let i = model.Suchbegriffe.length - 1; i >= 0; i--) {
      if (model.Suchbegriffe[i].trim().length === 0) {
        model.Suchbegriffe.splice(i, 1);
      }
    }

    model.Risikotraeger = model.Risikotraeger.toString().trim().split(',');
    for (let i = model.Risikotraeger.length - 1; i >= 0; i--) {
      if (model.Risikotraeger[i].trim().length === 0) {
        model.Risikotraeger.splice(i, 1);
      } else {
        model.Risikotraeger[i] = model.Risikotraeger[i].trim();
      }
    }
    if (model.Filter && typeof model.Filter === 'string') {
      try {
        model.Filter = JSON.parse(model.Filter);
      } catch (e) {
        model.Filter = {};
      } 
    }

    if (model.Defaults && typeof model.Defaults === 'string') {
      try {
        model.Defaults = JSON.parse(model.Defaults);
      } catch (e) {
        model.Defaults = {};
      }
    }

    if (model.Preis) {
      model.Preis = parseFloat(model.Preis);
    }

    model.Kategorien = [];

    if (model.KategorieEnterprise) {
      model.Kategorien.push({
        ID: model.KategorieEnterprise,
        Sortierung: parseInt(model.KategorieSortierung, 10)
      });
    }
    if (model.KategorieSnoopr) {
      model.Kategorien.push({
        ID: model.KategorieSnoopr,
        Sortierung: 0
      });
    }
    if(model.KategorienSonstige && model.KategorienSonstige.length > 0) {
      model.Kategorien.push(...model.KategorienSonstige);
    }
  }
}

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.css'],
  providers: [DatePipe]
})
export class ProductItemComponent extends ItemComponent implements OnInit {

  @Input() public product: Product;
  @Input() public panelOpenState = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';
  @Input() public tipsOpen = false;
  @Input() public initialValidation = false;

  @Input() public otherHaveDefaults = false;

  public provider: Provider;
  public editor: ProductEditor;
  public info: boolean;
  public isRoot = false;
  public isEnterprise = false;
  public editorInitialized = false;
  public assistant = false;
  public isEnded = false;

  private commissionTemplates = [{
    value: '50Abschlussprovision',
    label: '50 % Abschlussprovision',
    template: 'Für jede erfolgreiche Vermittlung von Versicherungsverträgen durch den Anbieter, \
      die auf die Namhaftmachung des Kunden durch den Tippgeber zurückzuführen ist, \
      erhält der Tippgeber eine einmalige Vergütung in Höhe von 50 % der abschlussbezogenen \
      Vermittlungsprovisionen (Tippgeber-Provision), die der Anbieter vom Versicherer erhält. \
      Die Tippgeber-Provision wird (jeweils) unverzüglich, spätestens jedoch innerhalb von \
      5 Tagen nach Erhalt der Provision vom Versicherer gezahlt. Bei Laufzeitverträgen erhält \
      der Tippgeber die Vergütung in mehreren Raten entsprechend der individuellen Frist für die \
      Stornohaftung des Versicherungsvertrages. Ansprüche auf noch nicht ausgezahlte Raten \
      verfallen, wenn zwar ein Versicherungsvertrag zustande gekommen ist, aber später \
      rückabgewickelt oder storniert wurde und der Anbieter selbst gegenüber dem Versicherer \
      zur Rückzahlung der Provision verpflichtet ist. Alle Zahlungen, die der Tippgeber bereits \
      erhalten hat, darf er behalten. Der Anbieter hat dem Tippgeber die Rückabwicklung oder \
      das Storno des Versicherungsvertrages auf Verlangen nachzuweisen.'
  }, {
    value: '50AbschlussFolgeprovision',
    label: '50 % Abschluss-/Folgeprovision',
    template: 'Für jede erfolgreiche Vermittlung von Versicherungsverträgen durch den Anbieter, \
      die auf die Namhaftmachung des Kunden durch den Tippgeber zurückzuführen ist, \
      erhält der Tippgeber eine einmalige Vergütung in Höhe von 50 % der abschlussbezogenen \
      Vermittlungsprovisionen (Tippgeber-Provision) sowie fortlaufende Vergütungen in Höhe von \
      50 % der laufenden Betreuungsprovision (Folge- / Bestandspflegeprovision), die der Anbieter \
      vom Versicherer erhält. \
      Die Tippgeber-Provision wird (jeweils) unverzüglich nach Erhalt, spätestens jedoch innerhalb von \
      5 Tagen nach Erhalt der Provision vom Versicherer gezahlt. Bei Laufzeitverträgen erhält \
      der Tippgeber die Vergütung in mehreren Raten entsprechend der individuellen Frist für die \
      Stornohaftung des Versicherungsvertrages. Ansprüche auf noch nicht ausgezahlte Raten \
      verfallen, wenn zwar ein Versicherungsvertrag zustande gekommen ist, aber später \
      rückabgewickelt oder storniert wurde und der Anbieter selbst gegenüber dem Versicherer \
      zur Rückzahlung der Provision verpflichtet ist. Alle Zahlungen, die der Tippgeber bereits \
      erhalten hat, darf er behalten. Der Anbieter hat dem Tippgeber die Rückabwicklung oder \
      das Storno des Versicherungsvertrages auf Verlangen nachzuweisen.'
  }, {
    value: 'Stueckprovision',
    label: 'XXX Euro Stückprovision',
    template: 'Für jede erfolgreiche Vermittlung von Versicherungsverträgen durch den Anbieter, \
      die auf die Namhaftmachung des Kunden durch den Tippgeber zurückzuführen ist, \
      erhält der Tippgeber eine einmalige Vergütung in Höhe von XXX Euro als Stückprovision \
      (Tippgeber-Provision) unabhängig von der Provision, die der Anbieter vom Versicherer erhält. \
      Die Tippgeber-Provision wird innerhalb eines (1) Monates nach dem Vertragsabschluss durch \
      Kunden gezahlt. Die Ansprüche auf noch nicht ausgezahlte Tippgeber-Provision erlöschen, \
      wenn zwar ein Versicherungsvertrag zustande gekommen ist, aber innerhalb eines Monats  \
      rückabgewickelt oder storniert wurde. Alle Zahlungen, die der Tippgeber bereits \
      erhalten hat, darf er behalten. Der Anbieter hat dem Tippgeber die Rückabwicklung oder \
      das Storno des Versicherungsvertrages auf Verlangen nachzuweisen.'
  }, {
    value: 'Individuell',
    label: 'Individuelle Provisionsregelung',
    template: 'Bitte beschreibe hier deine individuelle Provisionsregelung.'
  }];

  constructor(
    public dialog: MatDialog,
    public editorService: EditorService,
    private router: Router,
    private location: Location,
    public authService: AuthService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private messageService: MessageService,
    private navigationService: NavigationService,
    private mediaSetService: MediaSetService,
    private providerService: ProviderService,
    private profileService: ProfileService,
    private consumerService: ConsumerService
  ) {
    super();
  }

  createEditorSteps(categories: Category[], emailAddresses: Observable<any>): void {
    // Kategorien
    const categoriesEnterpriseOptions = [];
    console.log("categories", categories);
    
    let hasSnooprSelected = false;
    let hasEnterpriseSelected = false;
    let businessPlusParameterListe = []
    const isBroker = this.provider.AnbieterRolle === 'Makler';

    if (this.product.Kategorien) {

      for (let index = 0; index < this.product.Kategorien.length; index++) {
        const element = this.product.Kategorien[index];

        for (let c = 0; c < categories.length; c++) {
          const category = categories[c];
          if (category.ID === element.ID) {
            (<any>element).HatLeistungen = category.HatLeistungen;
            break;
          }
        }

        const split = /^(.{1,})-(.*)$/.exec(element.ID);
        if (split.length === 3) {
          const consumerSuffix = split[2];
          if (consumerSuffix !== 'SNOOPR') {
            hasEnterpriseSelected = true;
          } else {
            hasSnooprSelected = true;
          }
        }
      }
    }

    const selectedConsumerSuffix = this.consumerService.selectedConsumer.Suffix;
    (<any>this.product).KategorieEnterprise = null;
    (<any>this.product).KategorieSortierung = null;
    (<any>this.product).KategorieSnoopr = null;
    (<any>this.product).KategorienSonstige = [];
    if ((<any>this.product).Kategorien) {
      for (let index = 0; index < (<any>this.product).Kategorien.length; index++) {
        const element = (<any>this.product).Kategorien[index];
        const split = /^(.{1,})-(.*)$/.exec(element.ID);
        if (split.length === 3) {
          const consumerSuffix = split[2];

          if (consumerSuffix === selectedConsumerSuffix && consumerSuffix !== 'SNOOPR') {
            (<any>this.product).KategorieSortierung = element.Sortierung;
            (<any>this.product).KategorieEnterprise = element.ID;
          } else if(consumerSuffix === 'SNOOPR') {
            (<any>this.product).KategorieSnoopr = element.ID;
          } else {
            (<any>this.product).KategorienSonstige.push(element);
          }
        }
      }
    }

    (<any>this.product).KategorieSnooprLeistungen = false;
    categories.forEach((category: Category) => {
      const isSnoopr = category.ID.indexOf('-SNOOPR') > 0;
      if (isSnoopr && (<any>this.product).KategorieSnoopr === category.ID) {
        (<any>this.product).KategorieSnooprLeistungen = category.HatLeistungen;
      }

      if (isSnoopr) {
        return;
      }

      categoriesEnterpriseOptions.push({
        value: category.ID,
        group: category.ProduktgruppenID,
        label: category.Bezeichnung + (isSnoopr ? '' : (' (' + category.ID + ')')),
      });
    });

    const commissionOptions = [];
    for (let i = 0; i < this.commissionTemplates.length; i++) {
      const template = this.commissionTemplates[i];
      commissionOptions.push({ value: template.value, label: template.label });
    }

    // create step 1
    this.editor.steps.push({
      label: 'Werbetexte formulieren',
      fields: [{ // Tipp
        type: 'tip',
        templateOptions: {
          label: 'Tipps & Tricks',
          open: this.tipsOpen,
          description: '<p>Werbetexte sollten so formuliert werden, dass sie einfach und verständlich sind. \
          Häufig haben Produkte zahlreiche Optionen. Es hilft, sich Gedanken um die Zielgruppe zu machen,\
          die erreicht werden soll, um die Optionen des Produktes so zu schnüren, dass\
          sie zur Zielgruppe passen. Es sollten möglichst spezifische Informationen hinterlegt werden, da \
          allgemeine Produktinformationen im Ranking nicht optimal positioniert werden können. \
          Je genauer die Wünsche und Bedürfnisse von Suchenden adressiert werden, desto besser wird das Ranking \
          des Produktes sein und desto geringer ist die Preissensibilität der Kunden.</p> \
          <p>Der Titel dient dazu, die Zielgruppe direkt zu adressieren und der Untertitel fügt weitere \
          Details hinzu. Titel und Untertitel sind für die Zielgruppenansprache besonders wichtig und \
          in der Produktsuche von entsprechend hoher Relevanz. In den Highlights sollten die Produkteigenschaften \
          hervorgehoben werden, die für die Zielgruppe besonders wichtig sind.</p>\
          <p>Auf Fachsprache und Abkürzungen sollte möglichst verzichtet werden. Erfahrungswerte belegen, dass sowohl Makler \
          als auch Kunden nur selten nach Abkürzungen suchen. Tatsächlich verwenden selbst Fachleute eher laienhafte \
          Formulierungen bei der Produktsuche.</p>'
        }
      }, { // Überschrift
        template: '<p><strong>Gezielt die Zielgruppe ansprechen</strong></p>'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // Bezeichnung
          key: 'Bezeichnung',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            floatLabel: 'always',
            appearance: 'outline',
            placeholder: 'Titel des Produktes',
            description: 'Der Titel sollte unbedingt prägnant sein. \
              Über den Titel wird die Zielgruppe mit ihren Wünschen \
              und Bedürfnissen angesprochen. Er nimmt wesentlichen Einfluss auf das Ranking.',
            required: true,
            maxLength: 60,
            focus: () => { this.editorService.enter(this.product.ID, 'Bezeichnung'); },
            blur: () => { this.editorService.leave(); }
          },
          expressionProperties: {
            'templateOptions.label': (model) => 'Titel (' + model.Bezeichnung.length + ' / 60 Zeichen)'
          }
        }, { // Beschreibung
          key: 'Beschreibung',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            floatLabel: 'always',
            appearance: 'outline',
            placeholder: 'Untertitel des Produktes',
            description: 'Der Untertitel sollte den Titel konkretisieren. \
              Während der Titel die Zielgruppe grob adressiert, sollte der Untertitel \
              die Frage beantworten, warum das Produkt für die Zielgruppe besonders passt.',
            required: true,
            maxLength: 65,
            focus: () => { this.editorService.enter(this.product.ID, 'Beschreibung'); },
            blur: () => { this.editorService.leave(); }
          },
          expressionProperties: {
            'templateOptions.label': (model) => 'Untertitel (' + model.Beschreibung.length + ' / 65 Zeichen)'
          }
        }]
      }, {
        template: '<br>&nbsp;<br>',
        hideExpression: this.product.Modell !== 'BUSINESS_PRO',
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // ProduktBezeichnung
          key: 'ProduktBezeichnung',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            // tslint:disable-next-line:max-line-length
            description: 'Die Produktbezeichnung wird in den Folgeprozessen zur prägnanten Bezeichnung des Produkts verwendet. \
            Die Bezeichnung darf keinen werblichen Inhalt besitzen. Die Produktbezeichnung überschreibt die Standardbezeichnung \
            des Verkaufsproduktes bzw. der Kategorie im b-tix Tarifrechner. Beispiele: Tierhalterhaftpflicht, Privathaftpficht \
            oder Glasversicherung',
            floatLabel: 'always',
            appearance: 'outline',
            required: !(this.product.Modell !== 'BUSINESS_PRO' || (!this.isEnterprise && !this.isRoot)),
            maxLength: 50,
            focus: () => { this.editorService.enter(this.product.ID, 'ProduktBezeichnung'); },
            blur: () => { this.editorService.leave(); }
          },
          hideExpression: this.product.Modell !== 'BUSINESS_PRO' || (!this.isEnterprise && !this.isRoot),
          expressionProperties: {
            'templateOptions.label': (model) => 'Produktbezeichnung (' + model.ProduktBezeichnung.length + ' / 50 Zeichen)'
          }
        }, {
          type: 'button',
          className: 'apply-text-button',
          templateOptions: {
            icon: 'keyboard_arrow_left',
            tooltip: 'Titel auch als Produktbezeichnung übernehmen',
            onClick: (field) => {
              field.form.controls.ProduktBezeichnung.setValue(this.editor.model.Bezeichnung);
            }
          },
          hideExpression: this.product.Modell !== 'BUSINESS_PRO'
        }]
      }, { // Überschrift
        template: '<br/>&nbsp;<p><strong>Was macht das Produkt für die Zielgruppe besonders?</strong></p>' +
          // tslint:disable-next-line:max-line-length
          ((this.product.AnbieterRolle === 'Versicherer' && (this.isEnterprise || this.isRoot)) ? '<p class="mat-error">Es fließen nur die ersten 5 Highlights in die Suchmaschine Snoopr ein.</p>' : '')
      }, {
        key: 'Highlights',
        type: 'repeat-drag-drop',
        fieldArray: {
          fieldGroupClassName: 'display-flex',
          templateOptions: {
            label: 'Highlight hinzufügen',
            maxLength: (this.product.AnbieterRolle === 'Versicherer' ? ((this.isEnterprise || this.isRoot) ? 10 : 5) : 5),
            minLength: 1,
            onDrop: (previousIndex, currentIndex) => { }
          },
          fieldGroup: [{ // Text
            className: 'flex-1',
            type: 'input',
            key: 'Text',
            templateOptions: {
              floatLabel: 'always',
              required: true,
              maxLength: 80,
              placeholder: 'Besonderheit für die Zielgruppe?',
              appearance: 'outline',
              focus: (f) => {
                const fc = f.formControl.parent;
                const arr = fc.parent.controls;
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i] === fc) {
                    this.editorService.enter(this.product.ID, 'Highlight' + (i + 1));
                    break;
                  }
                }
              },
              blur: () => { this.editorService.leave(); }
            },
            expressionProperties: {
              'templateOptions.label': (model) => 'Highlight (' + (model && model.Text ? model.Text.length : 0) + ' / 80 Zeichen)'
            },
            validators: {
              required: {
                expression: (field) => {
                  if (!field.value) {
                    field.markAsTouched();
                    return false;
                  }
                  return true;
                }
              }
            }
          }]
        },
        validators: {
          minOne: {
            expression: (field) => {
              return (field.controls.length > 0);
            },
            message: () => 'Es muss mindestens ein Highlight eingetragen werden.',
          },
        }
      }]
    });

    this.editor.steps.push({
      label: 'Medien und Leistungsbeschreibungen hinzufügen',
      fields: [{ // Tipp
        type: 'tip',
        templateOptions: {
          label: 'Tipps & Tricks',
          open: this.tipsOpen,
          description: '<p>Hier gibt es die Möglichkeit, dem Produkt Medien \
          hinzuzufügen. Sie sollten möglichst genau zur Produktbeschreibung und der \
          adressierten Zielgruppe passen, da das Ranking des Produktes hierdurch \
          deutlich verbessert werden kann. Wir empfehlen dringend die Verwendung von Medien! Erfahrungswerte \
          belegen, dass Produkte mit Medien und ggf. Leistungsbeschreibungen besser gefunden \
          und öfter angeklickt werden.</p>\
          <p>Als Medien können Broschüren und Formulare im PDF-Format, Testsiegel in Form von \
          Bilddateien und YouTube-Videos am Produkt hinterlegen. Je nach Produktkategorie stehen \
          zusätzliche Leistungstabellen zu Verfügung, die unseren Nutzern einen \
          schnellen Überblick über das Produkt ermöglichen.</p>'
        }
      }, {
        template: '<p><strong>Medien verknüpfen</strong></p>\
        <p>Hier kannst du PDF-Dateien, YouTube-Videos und Bilddateien hochladen \
        und mit deinem Produkt verknüpfen. Das können z. B. Broschüren, Flyer, Erklärvideos, \
        werbliche Produktinformationen oder Bedingungswerke sein. Aus rechtlichen Gründen dürfen \
        ausdrücklich keine Antragsformulare direkt am Produkt hinterlegt werden. Medien werden \
        nicht einzeln am Produkt hinterlegt, sondern zunächst zu einem Paket gebündelt, bevor Sie \
        mit dem Produkt verknüpft werden. So lassen sich bestimmte Medienkombinationen einfach mit \
        verschiedenen Produkten verknüpfen. Für alle Medien ist ausschließlich der Anbieter des Produktes verantwortlich.</p>'
      }, {
        type: 'media-set-edit',
        templateOptions: {
          providerId: this.product.AnbieterID,
          providerName: this.product.AnbieterName,
          providerRole: this.product.AnbieterRolle,
          ref: '/product-edit/' + this.product.ID + '?step=2',
          getMediaSet: () => {
            return this.product.MediaSetInstanz;
          },
          onMediaSetChange: (newMediaSet: MediaSet) => {
            this.product.MediaSetInstanz = newMediaSet;
            this.product.MediaSet = newMediaSet ? newMediaSet.ID : '';
          }
        }
      }, {
        template: '<br><p><strong>Leistungsbeschreibung hinterlegen</strong></p>\
        <p>Für einzelne Produktkategorien liegt eine standardisierte Leistungstabelle vor. \
        Sie stellt eine detaillierte Auflistung der Eigenschaften eines Produktes dar. Das \
        Bearbeiten dieser Leistungstabelle ist freigestellt, dient aber der Suchmaschinenoptimierung. \
        Für die Angaben ist ausschließlich der Anbieter des Produktes verantwortlich.</p>',
        hideExpression: () => {
          return (!(<any>this.product).KategorieSnoopr || !(<any>this.product).KategorieSnooprLeistungen);
        }
      },

      // Leistungsbeschreibungen sollen zunächst von System-Usern und Normal-Usern erfolgen
      // Fuer Enterprise-Kunden muss wohl noch eine explizite Erläuterung stattfinden
      {
        template: '<p>Die Erfassung von Leistungen ist nicht freigeschaltet.</p>',
        hideExpression: () => {
          return !this.isEnterprise || (!(<any>this.product).KategorieSnoopr || !(<any>this.product).KategorieSnooprLeistungen);
        }
      },

      {
        type: 'button',
        templateOptions: {
          label: 'Leistungen bearbeiten',
          tooltip: 'Details zu Produkteigenschaften hinterlegen',
          color: 'primary',
          onClick: (field) => {
            this.navigationService.startLoading('product_benefits_loading');
            this.productService.getBenefits(this.product.ID).subscribe(benefits => {
              this.navigationService.stopLoading('product_benefits_loading');

              if (!benefits || !benefits.Leistungen || benefits.Leistungen.length === 0) {
                const genericDialog = new GenericDialog();
                genericDialog.titel = 'Leistungsdefinitionen';
                genericDialog.text = 'Für diese Produktkategorie sind zurzeit keine Leistungstabellen verfügbar.</p> \
                <p>&nbsp;</p><p>Alternativ können eigene \
                Leistungstabellen / -übersichten als Medium (PDF) hinzugefügt werden.';
                this.dialog.open(GenericDialogComponent, {
                  maxWidth: '500px',
                  maxHeight: '100vh',
                  data: genericDialog
                });
                return;
              }

              const dialogRef = this.dialog.open(LeistungsEditorDialogComponent, {
                panelClass: 'dialog-fullscreen',
                data: {
                  Template: benefits.Leistungen,
                  Data: (this.product.Leistungen && Object.keys(this.product.Leistungen).length > 0) ? this.product.Leistungen : {}
                }
              });
              dialogRef.afterClosed().subscribe((result: { save: boolean; data: any; }) => {
                if (result.save) {
                  this.product.Leistungen = result.data;
                }
                console.log('Dialog result:', result);
              });
            });
          }
        },
        hideExpression: () => {
          return this.isEnterprise || (!(<any>this.product).KategorieSnoopr || !(<any>this.product).KategorieSnooprLeistungen);
        }
      }, {
        template: '<br><p><strong>Defaults hinterlegen</strong></p>\
        <p>Für Produkte des Modells BUSINESS PRO können hier Vorbelegungen im JSON-Format definiert \
        werden, die bei der Beitragsermittlung zusammen mit den Controll-Eingaben angewendet werden.</p>',
        hideExpression: () => !this.isRoot || this.product.Modell !== 'BUSINESS_PRO',
      }, {
        key: 'Defaults',
        type: 'json',
        templateOptions: {
          label: 'Defaults bearbeiten',
          title: 'Vorbelegungen für die Beitragsermittlung hinterlegen'
        },
        hideExpression: () => !this.isRoot || this.product.Modell !== 'BUSINESS_PRO',
      }, {
        template: '<br><p><strong>Ein oder mehrere Produkte dieser Art haben Defaults.</strong></p>',
        hideExpression: () => !this.isRoot || this.product.Modell !== 'BUSINESS_PRO' || !this.otherHaveDefaults
      }]
    });

    this.editor.steps.push({
      label: 'Sucheinstellungen festlegen',
      fields: [{ // Tipp
        type: 'tip',
        templateOptions: {
          label: 'Tipps & Tricks',
          open: this.tipsOpen,
          description: '<p>Damit das Produkt gut gefunden wird, sollten hier bis zu zehn Stichworte \
          festgelegt werden, unter denen es gefunden werden soll. Bitte unbedingt darauf achten, \
          dass die Suchbegriffe auch tatsächlich zum Produkt passen, da unser \
          Suchalgorithmus das Produkt ansonsten abwertet.</p><p>Es ist für eine \
          Suche nicht förderlich, Produktbezeichnungen und Deckungsbezeichnungen wie z. B. \
          Vario XXL oder ähnliches zu verwenden, da nach diesen Begriffen gewöhnlich nicht spezifisch \
          gesucht wird. Um sicher zu stellen, dass das Produkt auch unter seinem Produktnamen gefunden wird \
          können die Bezeichnungen hier als Suchbegriff festgelegt werden.</p>'
        }
      }, {
        template: '<p><strong>Suchbegriffe festlegen</strong></p>\
        <p>Die Festlegung von Suchbegriffen trägt dazu bei, dass das Produkt besser gefunden wird. \
        Gibt der User Suchbegriffe ein, die den hier definierten entsprechen, wird das Produkt mit hoher Wahrscheinlichkeit \
        im Suchergebnis angezeigt. Die Suchbegriffe werden in Form von so genannten Tags am Produkt angezeigt.</p>'
      }, { // Stichworte
        key: 'Suchbegriffe',
        type: 'input',
        templateOptions: {
          floatLabel: 'always',
          appearance: 'outline',
          placeholder: 'z. B. drohne hexacopter octocopter',
          description: 'Stichworte, unter denen das Produkt in der Suchmaschine gefunden werden soll. \
            Stichworte sind durch Leerzeichen voneiner zu trennen. Bitte in die Lage des Suchenden \
            versetzen und möglichst wenig Fachbegriffe und Abkürzungen verwenden.'
        },
        expressionProperties: {
          'templateOptions.label': (model) => {
            let count = 0;
            if (typeof model.Suchbegriffe === 'string') {
              const split = model.Suchbegriffe.split(' ');
              for (let i = 0; i < split.length; i++) {
                if (split[i].trim().length > 0) {
                  count++;
                }
              }
            }
            return 'Suchbegriffe / Stichworte (' + count + ' / 10 Begriffe)';
          }
        },
        validators: {
          specialCharacter: {
            expression: (field) => {
              if (field.value !== '') {
                return (/^[A-Za-z0-9äÄöÖüÜß\-\ ]*$/.test(field.value));
              } else {
                return true;
              }
            },
            message: () => 'Sonderzeichen sind unzulässig.'
          },
          maxWords: {
            expression: (field) => {
              let count = 0;
              const split = (field.value || '').split(' ');
              for (let i = 0; i < split.length; i++) {
                if (split[i].trim().length > 0) {
                  count++;
                }
              }
              return count <= 10;
            },
            message: () => 'Maximal 10 Suchbegriffe zulässig.'
          },
          uniqueWords: {
            expression: (field) => {
              const split = (field.value || '').split(' ');
              for (let i = 0; i < split.length; i++) {
                split[i] = split[i].trim().toLowerCase();
              }

              for (let i = 0; i < split.length; i++) {
                if (split[i].length > 0 && split.indexOf(split[i], i + 1) !== -1) {
                  return false;
                }
              }
              return true;
            },
            message: () => 'Suchbegriffe dürfen nicht mehrmals angegeben werden.'
          }
        }
      }, { // Überschrift
        template: '<br/><p><strong>Produkt in Kategorie zuordnen</strong></p>',
        hideExpression: () => this.editor.model.KategorieEnterprise === null
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [

          { // Verkaufsprodukt
            key: 'KategorieEnterprise',
            type: 'select',
            className: 'flex-1',
            templateOptions: {
              multiple: false,
              required: true,
              disabled: (hasEnterpriseSelected && !this.isRoot) || (hasEnterpriseSelected && this.product.Modell === 'BUSINESS_PRO'),
              label: 'Verknüpfung zur Kategorie (Verkaufsprodukt)',
              floatLabel: 'always',
              appearance: 'outline',
              description: 'Ein Produkt muss mit einem Verkaufsprodukt verknüpft werden, \
                in dem es gelistet werden soll. Überlicherweise wird ein Produkt \
                nur in einer Kategorie gelistet.',
              options: categoriesEnterpriseOptions,
              compareWith: (o1, o2) => {
                if (o1 === null || o2 === null) {
                  return o1 === o2;
                }
                return o1 === o2;
              }
            },
            hideExpression: () => this.editor.model.KategorieEnterprise === null || selectedConsumerSuffix === 'SNOOPR'
          }
        ]
      }, { // Überschrift
        template: '<br>',
        hideExpression: () => this.editor.model.KategorieEnterprise === null
      }, { // Text
        type: 'input',
        key: 'KategorieSortierung',
        templateOptions: {
          label: 'Sortierung in der Kategorie',
          type: 'number',
          required: true,
          maxLength: 70,
          min: 0,
          placeholder: '',
          appearance: 'outline'
        },
        hideExpression: () => this.editor.model.KategorieEnterprise === null
      }, { // Überschrift
        template: '<p>&nbsp;<br/><strong>Produkt einer Snoopr-Kategorie zuordnen</strong></p>'
      }, {
        template: '<p>Unser Suchdienst versucht anhand der Eingaben des Nutzers und aus Suchen der \
            Vergangenheit darauf zu schließen, welche Art von Versicherung am ehesten zur Suchanfrage passt. \
            Hierbei setzen wir auf Künstliche Intelligenz (KI). Konnte unsere KI eine oder mehrere \
            Produktkategorien mit hoher Wahrscheinlichkeit ermitteln, dann werden Produkte der jeweiligen \
            Kategorie im Ranking potenziell weiter oben gelistet.</p>\
            <p>Handelt es sich bei dem Produkt um ein Bündel bzw. Deckungskonzept, das eigentlichen mehreren \
            Kategorien zugeordnet werden müsste, stehen hierfür entweder schon spezielle Kategorien zur \
            Verfügung oder können per E-Mail an service@snoopr.de beantragt werden. Der Nachteil einer neuen \
            Kategorie besteht darin, dass die KI diese erst aufgrund des Nutzerverhaltens erlenen muss. \
            Deshalb empfehlen wir, das Produkt lieber mehrfach mit unterschiedlichen Titeln und Unterntiteln \
            anzulegen. So werden diese Produkte tendenziell besser gefunden.</p>'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [

          { // Verkaufsprodukt
            key: 'KategorieSnoopr',
            type: 'category-select',
            className: 'flex-1',
            templateOptions: {
              single: true,
              required: false,
              disabled: hasSnooprSelected && !this.isRoot,
              label: 'Verknüpfung zur Kategorie (Verkaufsprodukt)',
              floatLabel: 'always',
              appearance: 'outline',
              isEdit: true,
              description: 'Ein Produkt muss mit einem Verkaufsprodukt verknüpft werden, \
                in dem es gelistet werden soll. Überlicherweise wird ein Produkt \
                nur in einer Kategorie gelistet.',
              categories: new Observable(subscriber => {
                const result = [];

                categories.forEach((category: Category) => {
                  if (category.ID === 'ALL' || category.ID === 'NONE') {
                    return;
                  }

                  if (category.ID.indexOf('-SNOOPR') < 0) {
                    return;
                  }
                  result.push({
                    value: category.ID,
                    group: category.ProduktgruppenID,
                    label: category.Bezeichnung,
                    disabled: false,
                    selected: (<any>this.product).KategorieSnoopr === category.ID
                  });
                });

                subscriber.next(result);
                subscriber.complete();
              })
            }
          }
        ]
      }, { // Überschrift
        template: '<br/><p><strong>Filterbedingungen einstellen</strong></p>',
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO' || !hasEnterpriseSelected,
      }, { // Text
        template: '<p>Die Filterbedingungen müssen im Schritt \'PRODUKT VERÖFFENTLICHEN\'<br/> \
        im Feld \'Technische Freigabe\' formuliert werden.</p>',
        hideExpression: () => this.isRoot || this.product.Modell !== 'BUSINESS_PRO' || !hasEnterpriseSelected,
      }, {
        key: 'Filter',
        type: 'json',
        templateOptions: {
          label: 'Filterbedingungen bearbeiten',
          title: 'Filterbedingungen bearbeiten'
        },
        hideExpression: () => !this.isRoot || this.product.Modell !== 'BUSINESS_PRO' || !hasEnterpriseSelected,
      }]
    });

    let strHtml = '';
    switch (this.product.Modell) {
      case 'BUSINESS':
        strHtml += '<p>Im Modell <strong>' + this.getModelName() + '</strong> wird das Produkt \
          mit den redaktionellen Inhalten und Medien in der Suche berücksichtigt. Ein Preis \
          wird nicht ausgewiesen. Stattdessen hat ein Nutzer über eine Schaltfläche die \
          Möglichkeit, eine Preisanfrage zu stellen.</p>';
        strHtml += '<p>Im Falle einer Preisanfrage senden wir eine E-Mail-Benachrichtigung \
          an die hinterlegte Adresse. Die E-Mail enthält einen Link, über den \
          der Vorgang (als Web-Chat) geöffnet werden kann. Danach besteht die Möglichkeit, \
          mit dem Nutzer zu chatten. Ist der Nutzer gerade nicht online, wird er automatisch per \
          Mail informiert.</p>';
        break;
      case 'BUSINESS_PLUS':
        strHtml += '<p>Im Modell <strong>' + this.getModelName() + '</strong> wird das Produkt \
          mit den redaktionellen Inhalten und Medien in der Suche berücksichtigt. Ein Preis \
          wird nicht ausgewiesen. Stattdessen hat ein Nutzer über eine Schaltfläche die \
          Möglichkeit, auf einen Online-Rechner abzuspringen.</p>';
        strHtml += '<p>Für dieses Modell müssen wir den Online-Rechner zuvor technisch \
          anbinden. Erst danach können Produkte von uns freigegeben werden. Wenn wir den \
          Tarifrechner anbinden sollen, dann benötigen wir eine E-Mail-Adresse \
          service@snoopr.de mit Details zum Online-Rechner.</p>';
        break;
      case 'BUSINESS_PRO':
        strHtml += '<p>Im Modell <strong>' + this.getModelName() + '</strong> werden die \
          die Tarifierungs-, Angebots- und Antragsprozesse vollständig integriert. Das hat \
          den Vorteil, dass ein Nutzer für die Preisanfrage bzw. den Angebots- oder Antragsprozess \
          nicht auf eine andere Anwendung weitergeleitet werden muss, sondern alles sofort in der \
          Anwendung erledigen kann. Hierdurch lassen sich evt. höhere Umwandlungsquoten erzielen.</p>';
        strHtml += '<p>Im Produkt müssen in diesem Fall die fachlichen Anforderungen an uns formuliert \
          werden, damit wir das Produkt entsprechend in der Suche berücksichtigen \
          können. Im Falle von Unsicherheiten, bitte einfach Kontakt mit uns aufnehmen. Zu den \
          wichtigsten Angaben gehört, unter welchen Filterbedingungen das Produkt erscheinen \
          soll.</p>';
        break;
      default:
        strHtml += '<p>Es wurden keine Inhalte gefunden.</p>';
    }


    //Vorbelegen, wenn nur 1 Link da ist
    if(this.provider.BusinessPlusParameterListe && this.provider.BusinessPlusParameterListe.length === 1) {
      this.product.BusinessPlusParameter = { 
        LinkID: this.provider.BusinessPlusParameterListe[0].LinkID,
        Wert:  this.provider.BusinessPlusParameterListe[0].Wert
      }
    }

    if( this.provider.BusinessPlusParameterListe && this.product.BusinessPlusParameter && this.product.BusinessPlusParameter.LinkID ) {
      for (let index = 0; index < this.provider.BusinessPlusParameterListe.length; index++) {
        if(this.provider.BusinessPlusParameterListe[index].LinkID === this.product.BusinessPlusParameter.LinkID) {
          this.product.BusinessPlusParameter.Wert = this.provider.BusinessPlusParameterListe[index].Wert;
          break;
        }
      }
    }

    this.editor.steps.push({
      label: 'Folgeprozess konfigurieren',
      fields: [{ // Tipp
        type: 'tip',
        templateOptions: {
          label: 'Tipps & Tricks',
          open: this.tipsOpen,
          description: strHtml
        },
        hideExpression: this.product.Modell === 'BUSINESS_PLUS',
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          { // Individuell
            key: 'Individuell',
            type: 'checkbox',
            templateOptions: {
              label: 'ohne Preisberechnung',
              description: ''
            },
            hideExpression: this.product.Modell !== 'BUSINESS_PRO',
            expressionProperties: {
              'templateOptions.disabled': () => !this.isRoot
            }
          },

          { // Vorbehaltstarifierung
            key: 'Vorbehaltstarifierung',
            type: 'checkbox',
            templateOptions: {
              label: 'Vorbehaltstarifierung',
              description: ''
            },
            hideExpression: this.product.Modell !== 'BUSINESS_PRO',
            expressionProperties: {
              'templateOptions.disabled': (model) => !this.isRoot || model.Individuell,
              'model.Vorbehaltstarifierung': (model) => !model.Individuell ? model.Vorbehaltstarifierung : false
            }
          }
        ]
      },
      { // BusinessPlus  Parameter
        key: 'BusinessPlusParameter.LinkID',
        type: 'select',
        className: 'multiline-select',
        templateOptions: {
          multiple: false,
          required: true,
          disabled: !this.provider.BusinessPlusParameterListe || this.provider.BusinessPlusParameterListe.length <= 1,
          label: 'Link/Parameter',
          floatLabel: 'always',
          appearance: 'outline',
          description: 'Weitere Links können unter service@snoopr.de beantragt werden.',
          options: (this.provider.BusinessPlusParameterListe || []).map((param) => ({
                value: param.LinkID,
                label: param.Beschreibung,
          })).sort((l, r) => l.label < r.label ? -1 : +(l.label > r.label)), // Sortierung,
          change: (form)=> { 
            for (let index = 0; index < this.provider.BusinessPlusParameterListe.length; index++) {
              if(this.provider.BusinessPlusParameterListe[index].LinkID === this.product.BusinessPlusParameter.LinkID) {
                form.formControl.parent.controls['Wert'].patchValue(this.provider.BusinessPlusParameterListe[index].Wert);
                break;
              }
            }
            }
        },   
       hideExpression: this.product.Modell !== 'BUSINESS_PLUS' || this.provider.BusinessPlusParameterListe == undefined || this.provider.BusinessPlusParameterListe.length == 0
      },{
        template: '<br>'
      }, {
        key: 'BusinessPlusParameter.Wert',
        type: 'textarea',
        templateOptions: {
          disabled: true,
          label: 'Vorschau des ausgewählten Links/Parameter',
          floatLabel: 'always',
          appearance: 'outline'
        },
        hideExpression: this.product.Modell !== 'BUSINESS_PLUS' || this.provider.BusinessPlusParameterListe == undefined || this.provider.BusinessPlusParameterListe.length == 0
      },  {
        template: '<br>'
      }, { // AbrechnungsfreiBis
        key: 'AbrechnungsfreiBis',
        type: 'extended-datepicker',
        templateOptions: {
          label: 'Abrechnungsfrei bis',
          floatLabel: 'always',
          placeholder: 'TT.MM.JJJJ',
          appearance: 'outline',
          // description: 'Tag, bis zu dem das Produkt abrechnungsfrei gestellt ist.',
          required: true,
          datepickerOptions: {
            startAt: moment('1970-01-01').toDate(),
            startView: 'month',
            touchUi: true
          }
        },
        hideExpression: !this.isRoot
      }, { // Überschrift
        template: '<p><strong>E-Mail hinterlegen</strong></p>',
        hideExpression: () => this.product.Modell !== 'BUSINESS' || isBroker
      }, {
        // tslint:disable-next-line:max-line-length
        template: '<p>Unser System informiert automatisch per E-Mail, wenn eine neue Preisanfrage vorliegt. \
          Diese Benachrichtung erfolgt an die hier eingebene E-Mail-Adresse. Durch Klick auf einen Button in \
          der Mail gelangt man auf den von unserem System bereitgestellten Chat. </p>',
        hideExpression: () => this.product.Modell !== 'BUSINESS' || isBroker
      }, { // ProduktBezeichnung
        key: 'Email',
        type: 'input',
        templateOptions: {
          label: 'Kontakt E-Mail-Adresse',
          description: '',
          floatLabel: 'always',
          appearance: 'outline',
          required: true
        },
        hideExpression: () => this.product.Modell !== 'BUSINESS' || isBroker
      }, {
        template: '<p>Unser System informiert automatisch per E-Mail, wenn eine neue Preisanfrage vorliegt. \
          Diese Benachrichtung erfolgt an die hier ausgewählte E-Mail-Adresse. Durch Klick auf einen Button in \
          der Mail gelangt man auf den von unserem System bereitgestellten Chat. Nur verifizierte \
          E-Mail-Adressen aus dem Profil können verwendet werden.</p>',
        hideExpression: () => this.product.Modell !== 'BUSINESS' || !isBroker
      }, { // ProduktBezeichnung
        key: 'Email',
        type: 'select',
        templateOptions: {
          label: 'Kontakt E-Mail-Adresse',
          description: '',
          floatLabel: 'always',
          appearance: 'outline',
          options: emailAddresses,
          required: true
        },
        hideExpression: () => this.product.Modell !== 'BUSINESS' || !isBroker
      }, {
        // tslint:disable-next-line:max-line-length
        template: '<p><strong>Fragen stellen</strong></p><p>Hier können Fragen formuliert werden die der Makler beantworten muss, bevor er eine Preisanfrage stellen kann. \
          Nach dem Öffnen der Preisanfrage, werden die Antworten im Chat ersichtlich. Es dürfen nur Fragen formuliert werden, die \
          deren Antworten für eine Preisermittlung oder Annahmeentscheidung wirklich notwendig sind. Später können jederzeit \
          weitere Fragen gestellt werden, um etwaige Besonderheiten zu berücksichtigen. Für diese Kommunikation stellt \
          unser System einen Web-Chat bereit, der das ermöglicht.</p>',
        hideExpression: this.product.Modell !== 'BUSINESS'
      }, {
        key: 'ProduktfragenValidator',
        type: 'hidden',
        validators: {
          minOne: {
            expression: () => {
              return typeof this.product.Fragen !== 'undefined' && this.product.Fragen.length > 0;
            },
            message: () => 'Es muss mindestens eine Frage hinterlegt werden.'
          }
        },
        hideExpression: this.product.Modell !== 'BUSINESS'
      }, {
        template: '<br>'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // ProduktBezeichnung
          type: 'button',
          templateOptions: {
            label: 'Produktfragen bearbeiten',
            tooltip: 'Produktfragen festlegen',
            color: 'primary',
            onClick: (field) => {
              this.productService.getBenefits(this.product.ID).subscribe(benefits => {
                const dialogRef = this.dialog.open(FormEditorDialogComponent, {
                  panelClass: 'dialog-fullscreen',
                  data: {
                    Titel: 'Fragenformular erstellen',
                    Form: {},
                    Fragen: this.product.Fragen
                  }
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result !== null) {
                    this.product.Fragen = result;
                  }
                });
              });
            }
          },
          hideExpression: this.product.Modell !== 'BUSINESS'
        }, {
          type: 'button',
          className: 'flex-item',
          templateOptions: {
            label: 'Beispiel anschauen',
            tooltip: 'Beispiel anschauen',
            color: 'primary',
            onClick: (field) => {
              const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
                data: <ImagePreviewDialog>{
                  ImageURL: '/assets/img/produktfragen-beispiel.png'
                }
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result !== null) {
                  this.product.Fragen = result;
                }
              });
            }
          },
          hideExpression: this.product.Modell !== 'BUSINESS'
        }]
      }, {
        // tslint:disable-next-line:max-line-length
        template: '&nbsp;<br/>&nbsp;<p><strong>Risikoträger</strong></p><p>Wir sind juristisch zur Transparenz verpflichtet, \
          den oder die Risikoträger hinter deinem Produktangebot zu nennen. Hierbei handelt es sich um den oder \
          die Erstversicherer. Der oder die Risikoträger sind auch dann zu nennen, wenn es sich bei dem originären Produktanbieter  \
          um einen Asskuradeur handelt.</p>',
        hideExpression: this.product.AnbieterRolle !== 'Makler'
      }, { // Risikotraeger
        key: 'Risikotraeger',
        type: 'input',
        templateOptions: {
          floatLabel: 'always',
          appearance: 'outline',
          placeholder: 'z. B. AXA, HDI, Helvetia',
          description: 'Risikoträger, der oder die hinter diesem Produkt stehen. Mehrere Risikoträger bitte mit Komma (,) trennen.',
          required: this.product.AnbieterRolle === 'Makler'
        },
        hide: this.product.AnbieterRolle !== 'Makler',
        expressionProperties: {
          'templateOptions.label': (model) => {
            let count = 0;
            if (typeof model.Risikotraeger === 'string') {
              const split = model.Risikotraeger.split(',');
              for (let i = 0; i < split.length; i++) {
                if (split[i].trim().length > 0) {
                  count++;
                }
              }
            }
            return 'Risikoträger (' + count + ' / 10)';
          }
        },
        validators: {
          maxWords: {
            expression: (field) => {
              let count = 0;
              const split = (field.value || '').split(',');
              for (let i = 0; i < split.length; i++) {
                if (split[i].trim().length > 0) {
                  count++;
                }
              }
              return count <= 10;
            },
            message: () => 'Maximal 10 Risikotraeger zulässig.'
          }
        }
      }, {
        template: '&nbsp;<br/>&nbsp;<p><strong>Provisionsregelung</strong></p><p>Wenn ein Vermittlerkollege auf dein \
        Produktangebot aufmerksam wird und dir seinen Kunden namhaft macht, schließt du automatisch \
        einen durch die renomierte Kanzlei Michaelis rechtlich geprüften Vertrag mit ihm über \
        eine Tippgeberschaft ab. Die nachfolgende Provisonsvereinbarung wird dem Vermittler frühzeitig nach Auswahl deines \
        Produktes angezeigt. Er muss sie zwingend bestätigen, bevor er einen Kontakt zu dir aufbauen \
        kann. Mit der Bestätigung und der Namhaftmachung des Kunden kommt eine Tippgeber-Vereinbarung \
        zustande. Die nachfolgende Provision ist fester Bestandteil dieser Tippgebervereinbarung. \
        Du kannst zwischen vorformulierten juristischen Texten wählen und diese beliebig abändern.</p>',
        hide: this.product.AnbieterRolle !== 'Makler'
      }, { // Provisionsregelung
        key: 'Provisionsregelung',
        type: 'select',
        templateOptions: {
          multiple: false,
          required: this.product.AnbieterRolle === 'Makler',
          label: 'Provisionsregelung',
          floatLabel: 'always',
          appearance: 'outline',
          description: '',
          options: commissionOptions,
          change: (field) => {
            const value = field.formControl.value;
            for (let i = 0; i < this.commissionTemplates.length; i++) {
              const template = this.commissionTemplates[i];
              if (template.value === value) {
                const provisionsregelungsText = (<any>field.formControl.parent.controls).ProvisionsregelungsText;
                const templateText = template.template;
                provisionsregelungsText.patchValue({ value: templateText, focus: true });
                break;
              }
            }
          }
        },
        hide: this.product.AnbieterRolle !== 'Makler'
      }, { // ProvisionsregelungsText
        key: 'ProvisionsregelungsText',
        type: 'quill',
        templateOptions: {
          required: this.product.AnbieterRolle === 'Makler',
          label: 'Provisionsregelungsbeschreibung',
          floatLabel: 'always',
          appearance: 'outline',
          description: '',
          rows: 8,
          maxLength: 3000,
          maxTextLength: 1500,
          tools: [
            'fett', 'kursiv', 'unterstrichen',
            'ueberschrift',
            'nummerierte-liste', 'liste',
            'formatierung_entfernen'
          ]
        },
        hide: this.product.AnbieterRolle !== 'Makler',
        hideExpression: () => !this.product.Provisionsregelung
      }]
    });
  

    this.editor.steps.push({
      label: 'Produkt veröffentlichen',
      fields: [{ // Tipp
        type: 'tip',
        templateOptions: {
          label: 'Tipps & Tricks',
          open: this.tipsOpen,
          description: '<p>Der früheste Zeitpunkt für eine Produktveröffentlichung \
          ist heute. Er kann maximal drei Monate im Voraus liegen. So lange das Produkt noch \
          nicht veröffentlicht wurde, kann der Zeitpunkt verändert, aber nicht in die \
          Vergangenheit gelegt werden.</p>\
          <p>In den meisten Fällen sollte das Produkt auf unbestimmte Zeit veröffentlicht \
          werden. Deshalb ist der Ablauf standardmäßig auf den 01.01.2099 vorbelegt. Über \
          Beginn und Ablauf können aber auch zeitlich begrenzte Veröffentlichungen vorgenommen werden, \
          z. B. wenn eine Aktion geplant ist.</p> \
          <p><strong>Wichtig:</strong> Das Produkt wird erst veröffentlicht, wenn die Funktion \
          "Veröffentlichen" geklickt wurde. Danach erfolgt noch eine Prüfung und es kann einige \
          Stunden dauern, bis das Produkt tatsächlich in der Suche erscheint. I.d.R. erfolgt die \
          Veröffentlichung über Nacht.</p>'
        }
      }, { // Leerzeile / Zwischenüberschrift
        template: '<p><strong>Zeitraum festlegen</strong></p>'
      }, { // 1. Zeile
        // key: 'Veroeffentlichung',
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // Beginn / Von
          key: 'Beginn',
          type: 'extended-datepicker',
          className: 'flex-1',
          templateOptions: {
            label: 'Von',
            floatLabel: 'always',
            placeholder: 'TT.MM.JJJJ',
            appearance: 'outline',
            description: 'Tag, ab dem das Produkt öffentlich verfügbar sein soll. \
              Die Ressource ist ab 0 Uhr des Tages verfügbar, aber i. d. R. nicht \
              sofort in der Anwendung sichtbar. Maximale Vorausdatierung: 3 Monate.',
            required: true,
            datepickerOptions: {
              min: moment(this.product.Beginn).isBefore(moment()) ? moment(this.product.Beginn) : moment(),
              max: moment().add(3, 'months'),
              startAt: moment(),
              startView: 'month',
              touchUi: true
            }
          }
        }, { // Ablauf / Bis
          key: 'Ablauf',
          type: 'extended-datepicker',
          className: 'flex-1',
          templateOptions: {
            label: 'Bis',
            floatLabel: 'always',
            placeholder: 'TT.MM.JJJJ',
            appearance: 'outline',
            description: 'Tag, ab dem das Produkt öffentlich nicht mehr verfügbar sein soll. \
              Die Ressource ist ab 0 Uhr des Tages nicht mehr veröffentlicht. Eine \
              unbegrenzte Dauer ist über ein spätes Ablaufdatum einzustellen.',
            required: true,
            datepickerOptions: {
              initDate: moment('2099-12-31'),
              min: moment().add(1, 'days'),
              max: moment('2099-12-31'),
              startAt: moment().add(1, 'years'),
              startView: 'year',
              touchUi: true,
              required: true
            }
          },
          defaultValue: moment('2099-01-01').toDate()
        }]
      }, { // Leerzeile / Zwischenüberschrift
        template: '<p><strong>Status verwalten</strong></p>',
        hideExpression: () => !this.isRoot && !this.isEnterprise, // only for b-tix and enterprise
      }, {
        // key: 'Stages',
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // Stages
          key: 'Stages',
          type: 'select',
          className: 'flex-1',
          templateOptions: {
            multiple: true,
            required: true,
            label: 'Systemumgebung',
            floatLabel: 'always',
            appearance: 'outline',
            description: 'Auswahl der Betriebsumgebung/en, auf der bzw. denen \
              das Produkt verfügbar sein soll. Je nach Benutzerkonto ist die \
              Auswahl eingeschränkt.',
            options: [
              { value: 'Entwicklung', label: 'Entwicklung', disabled: false },
              { value: 'Test', label: 'Test', disabled: false },
              { value: 'Systemtest', label: 'Systemtest', disabled: false },
              { value: 'Vorproduktion', label: 'Vorproduktion', disabled: false },
              { value: 'Produktion', label: 'Produktion / Live', disabled: false }
            ]
          },
          hideExpression: () => !this.isRoot && !this.isEnterprise, // only for b-tix and enterprise
          defaultValue: ['Test']
        }]
      }, { // Leerzeile
        template: '<br/>&nbsp;<br/>',
        hideExpression: () => (this.product.Status === 'ENTWURF' && !this.isRoot) || this.product.Modell !== 'BUSINESS_PRO'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // TarifrechnerVersionMin
          key: 'TarifrechnerVersionMin',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'b-tix Tarifrechner ab Version',
            floatLabel: 'always',
            placeholder: '-',
            appearance: 'outline',
            description: 'Version, ab der das Produkt im b-tix Tarifrechner \
              frühestens unterstützt wird.',
            required: false,
          },
          hideExpression: () => (this.product.Status === 'ENTWURF' && !this.isRoot) || this.product.Modell !== 'BUSINESS_PRO' && this.product.Modell !== 'BUSINESS_PLUS',
          expressionProperties: {
            // tslint:disable-next-line:max-line-length
            'templateOptions.required': () => (this.product.Modell === 'BUSINESS_PRO' || this.product.Modell === 'BUSINESS_PLUS') && ((<any>this.product).submitType === 'confirm'),
            'templateOptions.disabled': () => !this.isRoot
          },
          validators: {
            structure: {
              expression: (field) => {
                return (!field.value || field.value.length === 0) || /^\d+\.\d+(.\d+)?$/g.test(field.value);
              },
              message: () => 'Die Version ist ungültig. Beispiele: 19.01, 20.1',
            },
            isSmaller: {
              expression: (field) => {
                if (!this.product.TarifrechnerVersionMax) {
                  return true;
                }
                return field.value.localeCompare(this.product.TarifrechnerVersionMax) <= 0;
              },
              message: () => 'Die Version ist größer als die bis Version.',
            }
          }
        }, { // TarifrechnerVersionMax
          key: 'TarifrechnerVersionMax',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'b-tix Tarifrechner bis Version',
            floatLabel: 'always',
            placeholder: '-',
            appearance: 'outline',
            description: 'Version, ab der das Produkt im b-tix Tarifrechner \
              nicht mehr unterstützt wird.',
            required: false,
          },
          hideExpression: () => (this.product.Status === 'ENTWURF' && !this.isRoot) || this.product.Modell !== 'BUSINESS_PRO' && this.product.Modell !== 'BUSINESS_PLUS',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isRoot
          },
          validators: {
            structure: {
              expression: (field) => {
                return (!field.value || field.value.length === 0) || /^\d+\.\d+(.\d+)?$/g.test(field.value);
              },
              message: () => 'Die Version ist ungültig. Beispiel: 19.01, 20.1',
            },
            isBigger: {
              expression: (field) => {
                if (!this.product.TarifrechnerVersionMin || !field.value) {
                  return true;
                }
                return field.value.localeCompare(this.product.TarifrechnerVersionMin) >= 0;
              },
              message: () => 'Die Version ist kleiner als die ab Version.',
            }
          }
        }]
      }, { // Leerzeile
        template: '<br/>',
        hideExpression: () => {
          return !(this.authService.isEnterprise() || this.product.Modell === 'BUSINESS_PRO');
        }
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ // Sichtberechtigungen
          key: 'Berechtigungen',
          type: 'select',
          className: 'flex-1',
          templateOptions: {
            multiple: true,
            required: true,
            label: 'Sichtberechtigung',
            floatLabel: 'always',
            appearance: 'outline',
            description: 'Im Normalfall sollte ein Produkt sowohl für Vermittler als \
              auch für Verbraucher sichtbar sein. In seltenen Ausnahmen kann es sinnvoll sein, \
              Inhalte nur für Vermittler oder für Verbraucher zu veröffentlichen. ',
            options: [
              { value: 'ROLE_BROKER', label: 'Makler' },
              { value: 'ROLE_CUSTOMER', label: 'Verbraucher' },
            ]
          },
          defaultValue: ['ROLE_BROKER', 'ROLE_CUSTOMER'],
          hideExpression: () => !this.isRoot,
        },
        {
          template: '<p>Im Normalfall ist ein Produkt sowohl für Vermittler als \
              auch für Verbraucher sichtbar. In seltenen Ausnahmen kann es sinnvoll sein, \
              Inhalte nur für Vermittler oder für Verbraucher zu veröffentlichen. Bitte \
              formulieren Sie diese Anforderung im Bereich "Technische Freigabe".</p>',
          hideExpression: () => !this.isEnterprise,
        }]
      }, { // Leerzeile
        template: '<br/>&nbsp;<br/>',
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO'
      }, { // Status (nur Modell 3 Workflow)
        key: 'Status',
        type: 'select',
        templateOptions: {
          multiple: false,
          required: false,
          disabled: true,
          label: 'Status der Veröffentlichung',
          floatLabel: 'always',
          appearance: 'outline',
          description: 'Diese Anzeige ist maßgeblich für die Veröffentlichung \
            Ihres Produktes. Achtung: Es kann mehrere Stunden dauern, bis Ihr \
            Produkt tatsächlich in der Suchmaschine angezeigt wird.',
          options: [
            { value: 'ENTWURF', label: 'Entwurf (unveröffentlicht)', disabled: true },
            { value: 'IN_PRUEFUNG', label: 'In Prüfung (zur Veröffentlichung vorgelegt)', disabled: true },
            { value: 'FREIGEGEBEN', label: 'Live (veröffentlicht)', disabled: true }
          ]
        },
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO',
        defaultValue: ['ENTWURF']
      }, {
        template: '<br/>',
        hideExpression: () => !this.isRoot,
      }, {
        template: '<br/><p><strong>Erfolgsabhängige Gebühren für Klicks</strong></p>\
          <p>Für die Dauer des Mietverhältnisses erheben wir eine erfolgsabhängige \
          Vergütung für den Klick eines Nutzers auf die Schaltfläche "Preis anfragen" \
          innerhalb der Werbefläche. Der Cost per Click (CPC) beträgt 1 Euro zzgl. der \
          zum Zeitpunkt der Erhebung gültigen gesetzlichen Mehrwertsteuer. Für Werbeflächen \
          kann ein maximales Budget pro Monat vereinbart werden. Das Budget wird jeweils bei \
          der Anlage eines Produktes individuell festgelegt und muss mindestens 50€ Netto betragen. \
          Wenn das jeweilige Budget für eine Werbefläche erschöpft ist, endet die Veröffentlichung \
          des Produktes automatisch. Möglicherweise werden Produkte nach Erreichen des Budgets \
          noch kurzzeitig weiter angezeigt. Klicks, die während dieser Zeit auftreten, werden \
          weiter berechnet, maximal jedoch bis zu 20 % über dem Monatsbudget. Ein pauschaler \
          monatlicher Mietzins gilt darüber hinaus nicht als vereinbart.</p>',
        hideExpression: () => {
          return this.authService.isEnterprise() || this.product.Modell === 'BUSINESS_PRO';
        }
      }, {
        key: 'BudgetLimit',
        type: 'input',
        templateOptions: {
          label: 'Maximales Monatsbudget in € (Netto)',
          floatLabel: 'always',
          appearance: 'outline',
          placeholder: 'Kein Budgetlimit',
          type: 'number',
          min: 50,
          required: false
        },
        hideExpression: () => {
          return this.authService.isEnterprise() || this.product.Modell === 'BUSINESS_PRO';
        },
        expressionProperties: {
          'templateOptions.disabled': () => !(this.product.Status === 'ENTWURF') && !this.isRoot
        }
      }, { // Überschrift
        template: '<br/>&nbsp;<br/><p><strong>Technische Freigabe' + (!this.isRoot ? '*' : '') + '</strong></p>',
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO',
      }, { // Auftrag
        key: 'Auftrag',
        type: 'textarea',
        templateOptions: {
          label: 'Beschreibung (0 / 1000)',
          maxLength: 1000,
          rows: 6,
          appearance: 'outline',
          required: false,
          placeholder: 'Das Produkt soll bei folgenden Suchfiltern erscheinen / nicht erscheinen...',
          description: 'In welchen Konstellationen soll das Produkt erscheinen und bei welchen \
            Filtereinstellungen nicht? Wenn die vorgesehenen Daten aus der Suche alleine nicht \
            für eine Tarifierung ausreichen, mit welchen Annahmen soll der Beitrag berechnet werden?\
            Bitte beachten, dass dieses Feld nur im Status "Entwurf" bearbeitet werden kann.'
        },
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO',
        expressionProperties: {
          // tslint:disable-next-line:max-line-length
          'templateOptions.required': () => (<any>this.product).submitType !== '' && !this.isRoot,
          'templateOptions.disabled': () => !(this.product.Status === 'ENTWURF') && !this.isRoot,
          'templateOptions.label': (model) => 'Beschreibung (' + model.Auftrag.length + ' / 1000 Zeichen)'
        }
      }, { // Überschrift
        template: '<br/>&nbsp;<br/>',
        hideExpression: () => this.product.Modell !== 'BUSINESS_PRO',
      }]
    });
  }

  getModelName(): string {
    return this.product.Modell.replace('_', ' ');
  }

  initEditor() {

    if (!this.editorInitialized) {
      this.navigationService.startLoading('product_editor_init');

      this.editor = new ProductEditor(
        this,
        this.router,
        this.dialog,
        this.productService,
        this.messageService,
        this.editorService
      );

      forkJoin([
        this.product.ID ? this.productService.loadProduct(this.product.ID) : of(this.product),
        this.product.MediaSet ? this.mediaSetService.loadMediaSet(this.product.MediaSet) : of(null),
      ]).subscribe((result) => {
        this.product = result[0];
        this.product.MediaSetInstanz = result[1];

        this.editor.title = 'Produkt-ID: ' + this.product.ID + ' (' + this.getModelName() + ')';
        this.editor.setInfo1(() => 'Version ' + this.product.Version.toString()
          + ' vom ' + moment(this.product.Aenderungsdatum).format('DD.MM. [um] HH:mm [Uhr]'));
        this.editor.setInfo2(() => 'Erstelldatum '
          + moment(this.product.Erstelldatum).format('DD.MM.YYYY'));

        let emailAddresses = of([]);
        if (this.product.AnbieterRolle === 'Makler') {
          emailAddresses = this.profileService.loadProfile(this.product.AnbieterID).pipe(
            map(profile => profile.Emails.filter(email => email.Verifiziert).map(email => {
              return {
                value: email.Email,
                label: email.Email
              };
            }))
          );
        }

        this.providerService.loadProvider(this.product.AnbieterID).subscribe(provider => {
          this.provider = provider;
          if (this.authService.isDefault()) {
            this.createEditorSteps(this.categoryService.categoryList, emailAddresses);
            this.editorInitialized = true;
            setTimeout(() => {
              this.editorService.setModel(this.product);
            }, 0);
            this.navigationService.stopLoading('product_editor_init');
          } else {
            this.categoryService.loadCategoryList(true, true).subscribe((categories) => {
              this.createEditorSteps(categories, emailAddresses);
              this.editorInitialized = true;

              setTimeout(() => {
                this.editorService.setModel(this.product);
              }, 0);
              this.navigationService.stopLoading('product_editor_init');
            });
          }
        })

        
      });
    }
  }

  editProduct(edit): void {
    this.info = false;
    this.edit = edit;
    this.assistant = edit;

    if (edit) {
      this.initEditor();
    } else if (this.cancelRedirectUrl) {
      this.router.navigate([this.cancelRedirectUrl]);
    } else if (this.editorInitialized) {
      this.editorInitialized = false;
    }
  }

  isAllowedToPublish() {
    if (!this.authService.isBroker()) {
      return true;
    }
    return this.authService.isBrokerProvider() && this.authService.isBrokerVerified() && this.authService.isBrokerEligibleToPublish();
  }

  ngOnInit() {
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }
    this.isEnterprise = this.authService.isEnterprise();
    this.isRoot = this.authService.isSystem();

    this.isEnded = (moment() >= moment(this.product.Ablauf));
  }


  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }

  getSearchTerms() {
    if (!this.product.Suchbegriffe) {
      return [];
    }

    let result;
    if (this.product.Suchbegriffe instanceof Array) {
      result = this.product.Suchbegriffe.slice(0, this.product.Suchbegriffe.length);
    } else {
      result = (<any>this.product.Suchbegriffe).split(' ');
    }

    for (let i = result.length - 1; i >= 0; i--) {
      result[i] = result[i].trim().toLowerCase();
      if (result[i].length === 0) {
        result.splice(i, 1);
      }
    }
    return result;
  }
}
